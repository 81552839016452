import * as React from "react";
import { Heading, Text, Image, Flex, Box } from "rebass";
import Page from "../components/Page";
import IndexLayout from "../layouts";
import { Helmet } from "react-helmet";

import * as styles from "./ContactUs.module.css";
import * as technologyStyles from "./Technology.module.css";

import * as globalStyles from "../styles/Global.module.css";
import HeroLayoutEmpty from "../components/HeroLayoutEmpty";

import HeroGridCard from "../components/HeroGridCard";
import HeroGrid from "../components/HeroGrid";

import facebookIcon from "../assets/images/facebook-square.svg";
import linkedinIcon from "../assets/images/linkedin.svg";
import twitterIcon from "../assets/images/twitter-square.svg";

import ContactTestimonialImage from "../assets/images/contact-testimonial.png";
import ContactTestimonialLogo from "../assets/images/contact-testimonial-logo.png";

const ContactUsSuccessPage = () => {
  return (
    <IndexLayout>
      <Page>
        <HeroGrid
          style={{ position: "relative", overflow: "hidden", rowGap: "0" }}
        >
          <Helmet>
            <title>Skodel - Contact Us</title>
            <meta property="og:title" content="Skodel - Contact Us" />
            <meta
              property="og:description"
              content="Contact Skodel to learn more about our wellbeing solutions for your school community."
            />
          </Helmet>
          <HeroGridCard
            className={`${technologyStyles.heroTechnology}`}
            color="white"
            style={{
              zIndex: 3,
              maxWidth: "unset",
              marginBottom: "-568px",
              padding: "0",
            }}
          >
            <Box className={`${styles.heroContactStripes} brand-stripes`}>
              <Box className={`${styles.heroContactStripesInner}`}>
                <Box
                  className={`${styles.heroContactStripe} ${styles.heroContactStripe1}`}
                />
                <Box
                  className={`${styles.heroContactStripe} ${styles.heroContactStripe2}`}
                />
                <Box
                  className={`${styles.heroContactStripe} ${styles.heroContactStripe3}`}
                />
                <Box
                  className={`${styles.heroContactStripe} ${styles.heroContactStripe4}`}
                />
                <Box
                  className={`${styles.heroContactStripe} ${styles.heroContactStripe5}`}
                />
                <Box
                  className={`${styles.heroContactStripe} ${styles.heroContactStripe6}`}
                />
              </Box>
            </Box>
            <HeroLayoutEmpty
              className={`${styles.heroContact}`}
              mt={6}
              mx="auto"
              style={{
                display: "flex",
                width: "100%",
                maxWidth: "1164px",
                zIndex: 9001,
                paddingRight: "0",
                paddingLeft: "0",
              }}
            >
              <Box className={`${styles.heroContactForm}`}>
                <Box className={`${styles.contactGrid}`}>
                  <Box p={3} className={`${styles.contactFormTitle}`}>
                    <Heading
                      fontWeight={800}
                      style={{
                        maxWidth: "350px",
                        fontSize: "42px",
                        lineHeight: "52px",
                      }}
                      my={3}
                    >
                      Want to get in contact with us?
                    </Heading>
                    <Text
                      my={3}
                      style={{
                        fontSize: "17px",
                        lineHeight: "30px",
                        margin: "16px 0 0",
                      }}
                    >
                      Follow the prompts and we will be in contact with you
                      shortly.
                    </Text>
                  </Box>
                  <Box p={3} className={`${styles.contactFormAlternative}`}>
                    <div className={`${styles.contactFormSeparator}`} />
                    <Text
                      my={3}
                      style={{
                        fontSize: "17px",
                        lineHeight: "30px",
                        margin: "24px 0 0",
                      }}
                    >
                      or get in touch with social media
                    </Text>
                    <Flex className={`${styles.contactSocialList}`}>
                      <a
                        className={`${globalStyles.btn}`}
                        title="Skodel Facebook"
                        href="https://www.facebook.com/skodeltechnology/"
                      >
                        <Image src={facebookIcon} alt="Skodel Facebook" />
                      </a>
                      <a
                        className={`${globalStyles.btn}`}
                        href="https://twitter.com/Skodelwellbeing"
                        title="Skodel Twitter"
                      >
                        <Image src={twitterIcon} alt="Skodel Twitter" />
                      </a>
                      <a
                        className={`${globalStyles.btn}`}
                        href="https://www.linkedin.com/company/skodel"
                      >
                        <Image src={linkedinIcon} alt="Skodel LinkedIn" />
                      </a>
                    </Flex>
                  </Box>
                  <Box p={3} className={`${styles.contactFormWrapper}`}>
                    <div>
                      <>
                        <Heading fontWeight={800} fontSize={5}>
                          Thank you for contacting us!
                        </Heading>
                        <img
                          height="1"
                          width="1"
                          style={{ display: "none" }}
                          alt=""
                          src="https://px.ads.linkedin.com/collect/?pid=2535340&conversionId=3179596&fmt=gif"
                        />
                      </>
                    </div>
                  </Box>
                </Box>
              </Box>
            </HeroLayoutEmpty>
          </HeroGridCard>
          <HeroGridCard className={`${styles.contactTestimonialSection}`}>
            <Box className={`${styles.heroContactTestimonialStripes}`}>
              <Box className={`${styles.heroContactTestimonialStripesInner}`}>
                <Box className={`${styles.heroContactTestimonialStripe1}`} />
                <Box className={`${styles.heroContactTestimonialStripe2}`} />
                <Box className={`${styles.heroContactTestimonialStripe3}`} />
              </Box>
            </Box>
            <HeroLayoutEmpty
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box className={`${styles.contactTestimonialContent}`}>
                <Box
                  className={`${technologyStyles.technologyTestimonialItem}`}
                  style={{}}
                >
                  <Box
                    className={`${technologyStyles.technologyTestimonialItemWrapper}`}
                  >
                    <Box
                      className={`${technologyStyles.technologyTestimonialItemText}`}
                    >
                      <Text
                        style={{
                          fontSize: "17px",
                          lineHeight: "30px",
                          color: "#737B8B",
                        }}
                      >
                        Skodel gives us quick up to date information that allows
                        us to begin conversations of support where necessary. A
                        recent example was a senior boy. None of the staff were
                        aware that he had been carrying a worry that he
                        expressed on Skodel around with him for months, and the
                        information was just what we needed in order to provide
                        him with the support he needed.
                      </Text>
                      <Heading
                        style={{
                          marginTop: "27px",
                          fontWeight: "bold",
                          fontSize: "17px",
                          lineHeight: "30px",
                          color: "#232D42",
                        }}
                      >
                        Kevin Brennan,
                      </Heading>
                      <Heading
                        style={{
                          marginTop: "9px",
                          fontWeight: 600,
                          fontSize: "15px",
                          lineHeight: "24px",
                          color: "#232D42",
                        }}
                      >
                        Assistant Head of Senior School, The Scots College
                      </Heading>
                      <Image
                        src={ContactTestimonialLogo}
                        alt=""
                        style={{ maxWidth: "137px", marginTop: "26px" }}
                      />
                    </Box>
                    <Box
                      className={`${technologyStyles.technologyTestimonialItemImage}`}
                    >
                      <Image src={ContactTestimonialImage} alt="" />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </HeroLayoutEmpty>
          </HeroGridCard>
        </HeroGrid>
      </Page>
    </IndexLayout>
  );
};

export default ContactUsSuccessPage;
