// extracted by mini-css-extract-plugin
export var actionTechnology = "Technology-module--action--technology--QLYOb";
export var actionTechnologyBox = "Technology-module--action--technology-box--hF62V";
export var actionTechnologyGrid = "Technology-module--action--technology-grid--8kV6i";
export var actionTechnologyImage = "Technology-module--action--technology--image--wnBSP";
export var actionTechnologyTitle = "Technology-module--action--technology-title--MKMGC";
export var getBottomTechnology = "Technology-module--get-bottom--technology--YGzKD";
export var getBottomTechnologyStripe1 = "Technology-module--get-bottom--technology-stripe-1--Xws9m";
export var getBottomTechnologyStripe2 = "Technology-module--get-bottom--technology-stripe-2--IpbTH";
export var getBottomTechnologyStripe3 = "Technology-module--get-bottom--technology-stripe-3--Zgane";
export var getBottomTechnologyStripes = "Technology-module--get-bottom--technology-stripes--fEY3w";
export var getBottomTechnologyStripesInner = "Technology-module--get-bottom--technology-stripes-inner--f8cGa";
export var getBottomTechnology__content = "Technology-module--get-bottom--technology__content--diQNL";
export var getBottomTechnology__grid = "Technology-module--get-bottom--technology__grid--gRdjt";
export var getBottomTechnology__text = "Technology-module--get-bottom--technology__text--yJVrX";
export var handlingTechnology__grid = "Technology-module--handling--technology__grid---fJje";
export var heroTechnology = "Technology-module--hero--technology--EShq9";
export var heroTechnologyStripe1 = "Technology-module--hero--technology-stripe-1--wSEZR";
export var heroTechnologyStripe2 = "Technology-module--hero--technology-stripe-2--9HBwH";
export var heroTechnologyStripe3 = "Technology-module--hero--technology-stripe-3--4KdnH";
export var heroTechnologyStripe4 = "Technology-module--hero--technology-stripe-4--GDl0l";
export var heroTechnologyStripe5 = "Technology-module--hero--technology-stripe-5--xJwzI";
export var heroTechnologyStripe6 = "Technology-module--hero--technology-stripe-6--n-TCV";
export var heroTechnologyStripe7 = "Technology-module--hero--technology-stripe-7--bG6mz";
export var heroTechnologyStripes = "Technology-module--hero--technology-stripes--DyT7B";
export var heroTechnologyStripesInner = "Technology-module--hero--technology-stripes-inner--VfxMt";
export var heroTechnology__title = "Technology-module--hero--technology__title--M0WEy";
export var integrateTechnology = "Technology-module--integrate--technology--0CboR";
export var integrateTechnologyCard = "Technology-module--integrate--technology-card--BXCcJ";
export var integrateTechnologyCardTitle = "Technology-module--integrate--technology-card-title--Ob8Ad";
export var integrateTechnologyGrid = "Technology-module--integrate--technology-grid--8I8O4";
export var integrateTechnologyImage = "Technology-module--integrate--technology-image--r5ZZN";
export var integrateTechnologyImageApa = "Technology-module--integrate--technology-image-apa--rkQPk";
export var integrateTechnologyImageCoppa = "Technology-module--integrate--technology-image-coppa--KpoQY";
export var integrateTechnologyImageGdpr = "Technology-module--integrate--technology-image-gdpr--e1uRq";
export var integrateTechnologyTitle = "Technology-module--integrate--technology-title--69hS0";
export var integrationTechnology = "Technology-module--integration--technology--CR4Ex";
export var securityTechnology = "Technology-module--security--technology--dnM2y";
export var securityTechnologyStripe1 = "Technology-module--security--technology-stripe-1--sknJz";
export var securityTechnologyStripe2 = "Technology-module--security--technology-stripe-2--1IRrI";
export var securityTechnologyStripe3 = "Technology-module--security--technology-stripe-3--yYdV+";
export var securityTechnologyStripe4 = "Technology-module--security--technology-stripe-4--nVlku";
export var securityTechnologyStripe5 = "Technology-module--security--technology-stripe-5--2LpLv";
export var securityTechnologyStripes = "Technology-module--security--technology-stripes--ceLCC";
export var securityTechnologyStripesInner = "Technology-module--security--technology-stripes-inner---6HLm";
export var securityTechnology__content = "Technology-module--security--technology__content--NYx5z";
export var securityTechnology__contentGrid = "Technology-module--security--technology__content-grid--yBaiY";
export var securityTechnology__contentSubtitle = "Technology-module--security--technology__content-subtitle--xHUdH";
export var securityTechnology__contentTitle = "Technology-module--security--technology__content-title--T7RTu";
export var slickList = "Technology-module--slick-list--u0ioS";
export var technologyTestimonialCarousel = "Technology-module--technology-testimonial-carousel--l5m1n";
export var technologyTestimonialCarouselNext = "Technology-module--technology-testimonial-carousel-next--M7q3E";
export var technologyTestimonialCarouselPrev = "Technology-module--technology-testimonial-carousel-prev--1w7XW";
export var technologyTestimonialItem = "Technology-module--technology-testimonial-item--zAk5P";
export var technologyTestimonialItemImage = "Technology-module--technology-testimonial-item-image--9gqoM";
export var technologyTestimonialItemText = "Technology-module--technology-testimonial-item-text--aZyv5";
export var technologyTestimonialItemWrapper = "Technology-module--technology-testimonial-item-wrapper--fsYFK";
export var technologyTestimonialSection = "Technology-module--technology-testimonial-section--K7wXl";